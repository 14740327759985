@import "markdown.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
  /*   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  /*   sans-serif; */
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-200;
  @apply dark:bg-black;
  @apply dark:text-gray-50;
}
.monochrome-icon {
  @apply text-gray-500;
  @apply hover:text-gray-700;
  @apply dark:text-gray-500;
  @apply dark:hover:text-gray-300;
  @apply transition-colors;
  @apply duration-300;
  @apply text-base;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", */
  font-family: monospace;
}
