@tailwind base;
@layer base {
  .markdown {
    * {
      @apply dark:text-gray-50;
    }
    p {
      @apply text-sm;
    }
    th,
    strong {
      @apply font-bold;
    }
    code::before,
    code::after {
      content: none; /* Removes the backtick characters */
    }
    code {
      @apply px-1;
    }
    li {
      @apply list-item;
      @apply text-sm;
    }
  }
}
